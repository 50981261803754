






































































































































































































































































































































.newsList /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.searchbox {
  width: 100%;
}
.newsList {
  .el-form {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 0;
    span {
      color: #909399;
      font-size: 12px;
    }
  }
  .framePage-scroll {
    .bjInfo1 {
      background: #7f7f7f;
      width: 5px;
      height: 5px;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px;
      vertical-align: middle;
      margin-top: -3px;
    }
    .bjInfo2 {
      background: red;
      width: 5px;
      height: 5px;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px;
      vertical-align: middle;
      margin-top: -3px;
    }
    .bjInfo3 {
      background: #e6a23c;
      width: 5px;
      height: 5px;
      display: inline-block;
      border-radius: 50%;
      margin: 0 5px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }
    .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.newsList /deep/ .el-dialog__body {
  overflow: hidden;
  .block {
    width: 100%;
    .el-image {
      width: 48% !important;
      float: left;
      margin-bottom: 8px;
      margin-left: 1.5%;
    }
  }
}
